import {useState, useEffect} from 'react';
import  React  from 'react';
import {MDBCol, MDBListGroup, MDBListGroupItem, MDBRow, MDBTypography, MDBCardBody, MDBCard, MDBIcon} from "mdbreact";
import { Pie } from "react-chartjs-2";
import ImageComponent from "./ImageComponent.js"
import "./global_components.css"
const Panels = (props) => {
    return (
        <MDBCard color={props.color ? props.color : ""} className={(props.textAlign ? "text-left" : "text-center") + " px-2 " + props.className + " " + props.border}>
            <MDBCardBody className="p-1">
                {props.text}
            </MDBCardBody>
        </MDBCard>
    );
};


export default function (props) {
    const name_dish_class = (<>Назовите <strong>блюдо</strong></>);
    const ingr = (<>Назовите недостающий <strong>ингредиент</strong></>);
    let procen = Math.round(props.finishStat[0] / ( props.finishStat[0] + props.finishStat[1] ) * 100)
    let data = {
        labels: ["Верные ответы", "Не верные ответы"],
        datasets: [
            {
                data: props.finishStat,
                backgroundColor: [
                    "#25bf00",
                    "#F7464A",
                ],
                hoverBackgroundColor: [
                    "#73bf62",
                    "#FF5A5E",
                ]
            }
        ]
    };
    return(
        <MDBRow className="mb-4 h-100 pt-5 mt-5">
            <MDBCol xl="8" sm="8" className="mx-auto pb-3 text-center white z-depth-1 rounded">
                <MDBTypography tag="h1" variant="display-1" className={ !props.passed ? "text-danger mx-auto" : "text-success mx-auto"}>{procen}%</MDBTypography>
                <Pie data={data} options={{ responsive: true }}/>
            </MDBCol>
            <MDBCol xl="12" className="mx-auto">
                    <MDBListGroup className="w-100 mb-5">
                        {
                            props.questions.map( (item, index) => (
                                <MDBListGroupItem className="mt-3 white z-depth-1 rounded" key={index}>
                                    <MDBRow className={"border-left border_for_att_inf " +  (Boolean(item.is_correct) ? "border-success" : "border-danger")}>
                                        <MDBCol size="4" className={item.image ? "" : "d-none"}>
                                            <ImageComponent image={item.image} />
                                        </MDBCol>
                                        <MDBCol size={item.image ? "8" : "12"} className={item.image ? "pr-4" : ""}>
                                            <MDBRow>
                                                <MDBCol xl="12" className="px-1">
                                                    <Panels text={
                                                        item.mode !== "custom" ?
                                                            (<>
                                                                <h4 className="border-bottom">⁉️ { item.mode === "name_dish" ? name_dish_class : ingr }</h4>
                                                                <h5>{item.question}</h5>
                                                            </>)
                                                            :
                                                            <h4 className="border-bottom">{item.question}</h4>
                                                    } className={"z-depth-0 border"} textAlign="left"/>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow className="mt-1 px-1">
                                                {
                                                    item.answers.map( (value, index) => (
                                                        <MDBCol key={index} xl={value.answer.length < 60 ? "6" : "12"} lg={value.answer.length < 60 ? "6" : "12"} size="12" className="px-1">
                                                            {
                                                                item.correct_answers.includes(value.replyCode) ?
                                                                    <MDBIcon icon="check" className="position-absolute green-text" style={{ zIndex: 100, left: "-0.3%", top: "-6%"}}/>
                                                                    :
                                                                    ""
                                                            }
                                                            <Panels text={value.answer} border={
                                                                (item.correct_answers.includes(value.replyCode) && item.user_answers?.includes(value.replyCode)) ?
                                                                    "border-success" :
                                                                    (item.user_answers?.includes(value.replyCode) && (item.correct_answers.includes(value.replyCode) === false))
                                                                        ? "border-danger" : ""
                                                            } className={"mt-1 z-depth-0 border"}/>
                                                        </MDBCol>
                                                    ))
                                                }
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBListGroupItem>
                            ))
                        }

                    </MDBListGroup>
            </MDBCol>
        </MDBRow>
    )
}
