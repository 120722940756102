import {useState, useEffect} from 'react';
import  React  from 'react';
import {MDBAlert, MDBBox, MDBCol, MDBContainer, MDBRow} from "mdbreact";


export default function (props) {
    return(
        <MDBRow className="h-100">
            <MDBBox display="flex" alignItems="center" style={{flexGrow: 1}} className="">
                <MDBBox style={{flex: 1.5, textAlign: "center"}} className="ml-3 mr-2">
                    {
                        props.qr ?
                            (
                                <>
                                    <img src={props.qr} className="img-fluid p-2 z-depth-1 white d-block mx-auto rounded mb-2 mt-5"
                                        alt="" style={{zIndex: ""}}/>

                                    <h3 className="white z-depth-1 rounded px-2 py-1" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                        <div>🔑</div>
                                        <div>{props.password}</div>
                                        <div>🔑</div>
                                    </h3>
                                </>)
                            :
                            (<div className="spinner-border text-primary" role="status">
                                <span className="sr-only mx-auto">Загрузка....</span>
                            </div>)
                    }
                </MDBBox>
                <MDBBox style={{flex: 2, padding: "10px"}} className="z-depth-1 white rounded mr-3 ml-2">
                    <h4 className="mx-auto">
                        ⏱️ На каждый вопрос у тебя 1 минута<br/>
                        ⁉️ Читай внимательно, ты не сможешь вернуться к вопросу<br/>
                        ⬅️ Для начала аттестации отсканируй QR код или отправь Боту пароль указанный внизу QR кода<br/>
                        🤖 Нажми кнопку "Начать" в открывшемся чат-боте после сканирования QR кода<br/>
                        При вводе пароля аттестация начнётся автоматически<br/>
                        🤓 Аттестация начнется здесь, на экране компьютера<br/>
                        🥇 Вперёд и удачи!
                    </h4>
                </MDBBox>
            </MDBBox>
        </MDBRow>
    )
}
