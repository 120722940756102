import {useState, useEffect} from 'react';
import  React  from 'react';
import {MDBBox, MDBBtn, MDBCol, MDBContainer, MDBRow, MDBTypography} from "mdbreact";


import AnswersButtonGroup from "./AnswersButtonGroup";
import ImageComponent from "./ImageComponent";

export default function(props) {
    const name_dish_class = (<>Назовите <strong>блюдо</strong></>);
    const ingr = (<>Назовите недостающий <strong>ингредиент</strong></>);

    return (
        <MDBRow className="w-100 mt-3">
            <MDBCol sm="5">
                <ImageComponent image={props.image} className="z-depth-1 rounded img-fluid white mx-auto"/>
            </MDBCol>

            <MDBCol sm="7" className=''>
                <MDBRow className="no-gutters">
                    <MDBCol xl="12" className="z-depth-1 pt-3 pb-2 px-3 rounded white">
                        {
                            props.modeQ !== "custom" ? (
                                <>
                                    <h3>⁉️<span className="grey-text mr-1 ml-1">{props.number[0]}/{props.number[1]}</span>{ props.modeQ === "name_dish" ? name_dish_class : ingr }</h3>
                                    <br/>
                                    <h4>{props.question}</h4>
                                </>
                            ) : (
                                <>
                                    <MDBTypography tag='h3' variant="h3" className="text-muted border-bottom">⁉️<span className="grey-text mr-1 ml-1">{props.number[0]}/{props.number[1]}</span> Вопрос: </MDBTypography>
                                    <h4>{props.question}</h4>
                                </>
                            )
                        }
                    </MDBCol>
                </MDBRow>
                <AnswersButtonGroup
                    answers={props.answers}
                    activeButtons={props.activeButtons}
                    onClickAns={props.onClickAns}
                    answerMode={props.answerMode}
                />
                <MDBRow className="no-gutters mt-2 px-0">
                    <MDBBtn className="w-100 z-depth-1 mx-0"
                            style={{fontSize: "12pt"}}
                            color="danger"
                            disabled={props.disableButton}
                            onClick={() => props.nextQuestion()}
                    >Далее</MDBBtn>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    )
}
