import {useState, useEffect} from 'react';
import  React  from 'react';
import {MDBBox, MDBCol, MDBContainer, MDBProgress, MDBRow} from "mdbreact";


export default function (props) {
    return(
        <MDBBox style={{flex: 1}}>
            <MDBBox style={{flex: 1}}>
                <h1 className={100/props.maxTimer * props.timer < 25 ? "text-center display-1 text-danger" : "text-center display-1 text-success"} ><strong>{props.timer}</strong></h1>
            </MDBBox>
            <MDBBox style={{flex: 1}}>
                <MDBProgress value={100/props.maxTimer * props.timer} className="my-2 z-depth-1" striped animated
                             color={100/props.maxTimer * props.timer < 25 ? "danger" : "success"  } />
            </MDBBox>
        </MDBBox>
    )
}
