import React, {useEffect, useState} from "react";

export default function ImageComponent(props) {
    const [link, setLink] = useState();

    useEffect(() => {
        if (props.image) {
            props.image.indexOf('https://sushiwok') >= 0 ?
                setLink(props.image)
                :
                fetch(props.image, {
                    method: "GET",
                    headers: {
                        "Authorization": 'Basic T1RHQURcc2VydmljZXVzZXI6T2hqYWg3a3U=',
                    }
                }).catch(e => console.log(e))
                    .then((data) => data.blob())
                    .catch(e => console.log(e))
                    .then((img) => {
                        let reader = new FileReader();

                        reader.readAsDataURL(img);

                        reader.onload = function () {
                            setLink(reader.result);
                        };

                        reader.onerror = function () {
                            console.log(reader.error);
                        };
                    })
        }
    }, [props.image]);
    return (
        <img src={link} className={"img-fluid " + props.className} alt=""/>
    )
}
