import  React  from 'react';
import {MDBBtn, MDBIcon } from "mdbreact";

export default function (props) {
    return(
        <div className="white p-0 my-2 z-depth-1">
            <MDBBtn className="w-100 p-0 m-0 white" style={{minHeight: "80px", fontSize: "12pt"}} outline={!props.active} color={!props.active ? "primary" : "success" }  onClick={() => { props.onClick()} }>
                {
                    props.answerMode === "multiple" ?
                        (<>
                            <MDBIcon far icon={!props.active ? "square" : "check-square"} className="mr-2"/>
                            {props.title}
                        </>) : props.title
                }
            </MDBBtn>
        </div>
    )
}
