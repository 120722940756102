import {useState, useEffect} from 'react';
import  React  from 'react';
import {MDBBox, MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow} from "mdbreact";
import AnsButton from "./AnsButton";


export default function (props) {
    return (
        <>
            <MDBRow className="">
                {
                    props.answers.map( (value, index) => {
                            if (typeof value === "object") {
                                return (
                                    <MDBCol xl={value.answer.length < 60 ? "6" : "12"} lg={value.answer.length < 60 ? "6" : "12"} size="12" key={index}>
                                        <AnsButton title={value.answer}
                                                   active={props.activeButtons.findIndex( (ar_value) => ar_value === value.replyCode) >= 0}
                                                   answerMode={props.answerMode}
                                                   onClick={() => props.onClickAns(value.replyCode)}/>
                                        </MDBCol>
                                )
                            }
                            return (
                                <MDBCol xl={value.length < 60 ? "6" : "12"} lg={value.length < 60 ? "6" : "12"} size="12" key={index}>
                                    <AnsButton title={value}
                                       active={props.activeButtons.findIndex( (ar_value) => ar_value === index) >= 0}
                                       answerMode={props.answerMode}
                                       onClick={() => props.onClickAns(index)}/>
                                </MDBCol>
                            )
                        }
                    )
                }
            </MDBRow>
        </>
    )
}
