import {useState, useEffect} from 'react';
import  React  from 'react';
import {MDBBox, MDBCol, MDBContainer, MDBProgress, MDBRow} from "mdbreact";
import Timer from './Timer'

export default function (props) {
    const maxTimer = 9;
    return(
        <MDBContainer className="h-100">
            <MDBRow className="mb-4 h-100">
                <MDBBox display="flex" alignItems="center" style={{flexGrow: 1}}>
                    <MDBBox style={{flex: 1}}>
                        <Timer timer={props.timer} maxTimer={maxTimer}/>
                    </MDBBox>
                </MDBBox>
            </MDBRow>
        </MDBContainer>
    )
}
