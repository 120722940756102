import {useState, useEffect} from 'react';
import  React  from 'react';
import {MDBBox, MDBBtn, MDBCol, MDBContainer, MDBRow} from "mdbreact";
import Timer from './Timer'
import WithImage from './WithImage'
import WithoutImageQuestion from "./WithoutImageQuestion";

export default function (props) {
    const maxTimer = props.maxTime;
    return(
        <MDBRow className="h-100">
            <MDBBox display="flex" alignItems="center" justifyContent="center" style={{flexGrow: 1, flexDirection: "column"}}>
                <MDBRow className="w-100">
                    <MDBCol xl="12" >
                        <Timer timer={props.timer} maxTimer={maxTimer}/>
                    </MDBCol>
                </MDBRow>
                {
                    props.image ?
                        <WithImage number={props.number}
                                   question={props.question}
                                   image={props.image}
                                   modeQ={props.modeQ}
                                   activeButtons={props.activeButtons}
                                   answers={props.answers}
                                   answerMode={props.answerMode}
                                   disableButton={props.disableButton}
                                   nextQuestion={props.nextQuestion}
                                   onClickAns={props.onClickAns}
                        />
                        :
                        <WithoutImageQuestion number={props.number}
                                              question={props.question}
                                              modeQ={props.modeQ}
                                              activeButtons={props.activeButtons}
                                              answers={props.answers}
                                              answerMode={props.answerMode}
                                              onClickAns={props.onClickAns}
                                              disableButton={props.disableButton}
                                              nextQuestion={props.nextQuestion}
                        />
                }
            </MDBBox>
        </MDBRow>
    )
}
