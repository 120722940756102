import {useState, useEffect, useRef} from 'react';
import React  from 'react';
import io from "socket.io-client";
import ss from 'socket.io-stream';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
//screenes
import AuthScrine from "./components/AuthScrine.js";
import StartScrine from './components/StartScrine'
import QuizeScrine from './components/QuizeScrine'
import FinishScrine from './components/FinishScrine'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {MDBAlert, MDBContainer} from "mdbreact";

const ENDPOINT = "https://tv-board.sw-bot.ru";
// const ENDPOINT = "http://localhost:4030/";

const CAPTURE_OPTIONS = {
    audio: false,
    video: { width: 720, height: 720 },
};

function App() {
    const [disconnect, setDisconnect] = useState();
    const [scene, setScrine] = useState();
    const [qr, setQr] = useState();
    const [password, setPassword] = useState();
    const [timer, setTimer] = useState();
    const [maxTime, setMaxTime] = useState();
    const [question, setQuestion] = useState([]);
    const [socketIO, setSocketIO] = useState();
    const [activeButtons, setActiveButtons] = useState([]);
    const [number, setNumber] = useState([]);
    const [finishStat, setFinishStat] = useState([]);
    const [passed, setPassed] = useState();
    const [questions, setQuestions] = useState([]);
    const [disableButton, setDisableButton] = useState(false);

    const [mediaStream, setMediaStream] = useState();

    let createStream = async () => {
        return await navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS);
    }

    let createMediaRecorder = async (mediaStream, ) => {
        return new Promise((resolve, reject) => {
            let mediaRecorder = new MediaRecorder(mediaStream, {mimeType: 'video/webm;codecs="vp9"'});
            const socket = io(ENDPOINT);
            let close = false
            socket.on('connect', async () => {
                let stream = ss.createStream();
                function closeStream() {
                    if (close) {
                        stream.end()
                    }
                }
                mediaRecorder.onstop = function (e) {
                    close = true
                }
                mediaRecorder.onstart = () => {
                    console.log("start")
                }
                mediaRecorder.ondataavailable = function (e) {
                    // socket.emit('video_chunk', {data: e.data})
                    e.data.arrayBuffer().then(buffer => {
                        stream.write(new ss.Buffer(buffer));
                        closeStream()
                    });
                }
                ss(socket).emit('video_chunk', stream, {userId: localStorage.getItem('user_token')});
                resolve(mediaRecorder);
            });
        })
    }

    useEffect( () => {
        async function main() {
            let mediaStream = await createStream().catch((e) => {console.log(e); return null});
            setMediaStream(mediaStream);
            let mediaRecorder;
            let record = false;
            const socket = io();

            socket.on('connect', async () => {
                setSocketIO(socket);
                setDisconnect(false);
                socket.emit('authorization', localStorage.user_token, async url => {
                    if (url) {
                        let pass = url.split("?start=")
                        setPassword("-2-" + pass[1].slice(3, 16))
                        setQr(`http://api.qrserver.com/v1/create-qr-code/?data=${url}&size=500x500`)
                    }
                });
            });

            socket.on("done", () => {
                window.alert("Видео загруженно")
            })

            socket.on('disconnect', (reason) => {
                console.log(reason);
                setDisconnect(true)
            });

            socket.on('authorizedUser', (msg) => {
                //Телеграм id пользователя Теперь он авторизован
                localStorage.setItem('user_token', msg);
                setQr(null)
            });

            socket.on('data', (msg) => {
                if (mediaStream && !mediaRecorder) {
                     createMediaRecorder(mediaStream).then(data => {
                         mediaRecorder = data
                     });
                }
                if (mediaRecorder && !record) {
                    mediaRecorder.start(1000)
                    record = true
                }

                let data = JSON.parse(msg);
                let mode = data.mode;
                setScrine(mode);
                if (data.mode === "finish") {
                    if (mediaRecorder && record) {
                        mediaRecorder.stop();
                        setMediaStream(null);
                        record = false
                    }
                    setQuestions(data.questions);
                    setFinishStat([data.correct[0].correct, data.number_of_question - data.correct[0].correct]);
                    setPassed(data.passed);
                } else {
                    if (data.time >= 0) {
                        setTimer(data.time);
                    }
                    if (data.question) {
                        setQuestion(data.question);
                        setNumber(data.number);
                        setMaxTime(data.secondsPerQuestion)
                        setActiveButtons(data.question[0].user_answers ? data.question[0].user_answers : []);
                        setDisableButton(false);
                    }
                }
            })

            socket.on('connect_error', (error) => {
                console.log(error)
                setDisconnect(true)
            });
        }
        main()
    }, []);

    let sendAnswer = (id) => {
        let activeButtonsCopy = activeButtons.slice()

        if (question[0].answerMode === "multiple") {
            if (activeButtonsCopy.findIndex(value => value === id) >= 0) {
                activeButtonsCopy = activeButtonsCopy.filter( value => value !== id)
            } else {
                activeButtonsCopy.push(id)
            }
        } else activeButtonsCopy = [id]


        socketIO.emit('sendAnswers', JSON.stringify({
            telegram_id: localStorage.user_token,
            question_id: question[0].certification_session_question_id,
            answers: activeButtonsCopy.sort()
        }));

        setActiveButtons(activeButtonsCopy.sort())
    };
    let nextQuestion = () => {
      setDisableButton(true);
      socketIO.emit('nextQuestion', localStorage.user_token)
  };

    const videoRef = useRef();

    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
        videoRef.current.srcObject = mediaStream;
    }

  return (
      <>
          {
              scene !== "finish" ?
                  <video ref={videoRef} autoPlay style={{
                      width: "140px",
                      position: "absolute",
                      top: 80, left: 10,
                  }}/> : ""
          }
          {
            disconnect ? (
                    <MDBAlert color="danger" className="position-absolute w-100">
                      <strong>Потерянно соединение с свервером!</strong> Проверьте соединение с интернетом и дождитесь автоматического соединения.
                    </MDBAlert>) :
                (
                    <>
                        <img src="logo.svg" alt="Суши Wok" style={{width: "250px", top: 10, left: 1, zIndex: "-1"}} className="position-absolute rounded mx-auto d-none d-md-block"/>
                        <img src="logo.png" alt="SW_Bot" style={{width: "100px", top: 10, right: 10,  zIndex: "-1"}} className="position-absolute rounded white mx-auto d-none d-md-block"/>
                    </>
                )
          }
          <MDBContainer size="xl" className="h-100">
                { !scene ? <AuthScrine qr={qr} password={password}/> : ""}
                { scene === "start" ? <StartScrine timer={timer} /> : ""}
                { scene === "quiz" && question[0] ? <QuizeScrine
                    timer={timer}
                    maxTime={maxTime}
                    modeQ={question[0].mode}
                    question={question[0].question}
                    answers={question[0].answers}
                    number={number}
                    image={question[0].image}
                    onClickAns={sendAnswer}
                    activeButtons={activeButtons}
                    nextQuestion={nextQuestion}
                    disableButton={disableButton}
                    answerMode={question[0].answerMode}
                /> : ""}
                { scene === "finish" ? <FinishScrine finishStat={finishStat} questions={questions} passed={passed}/> : ""}
          </MDBContainer>
      </>
  );
}

export default App;
